<template>
  <div>
    <ListSiproquim />
  </div>
</template>

<script>
import ListSiproquim from '@/components/siproquim/ListSiproquim.vue';

export default {
  components: {
    ListSiproquim
  }
}
</script>