<template>
  <div>
    <header-register :showButtonView="true" showUrl="/siproquim/addSiproquim">Código siproquim
    </header-register>
    <search-bar-register class="mt-2 mb-2 sm:mb-3" :title="`Pesquisa de código de siproquim`"
      @input="filter = $event" />
    <b-table responsive="sm" :fields="fields" primary-key="CódigoSiproquim" :items="items" :filter="filter"
      :perPage="itemsPerPage" show-empty empty-text="Não existem códigos siproquim cadastrados"
      :empty-filtered-text="`Código siproquim não encontrado`">
      <template #cell(CódigoSiproquim)="data">
        {{ Indice + (data.index + 1) }}
      </template>
      <template #cell(ações)="data">
        <div class="acoes">
          <div>
            <button @click="RedirectUrl(`/siproquim/viewSiproquim`,data.item)">
              <EyeIcon class="w-5 mr-2 icon__actions" />
            </button>
          </div>
          <div>
            <button @click="RedirectUrl(`/siproquim/editSiproquim`,data.item)">
              <EditIcon class="w-4 mr-2 icon__actions" />
            </button>
          </div>
          <div>
            <button @click="deletarFornecedor(data.item.id)">
              <DeleteIcon class="w-5 mr-2 icon__actions" />
            </button>
          </div>
        </div>
      </template>
    </b-table>

    <footer class="mt-2 flex items-center">
      <Pagination :totalItems="totalItems" :itemsPerPage="itemsPerPage" text="código siproquim" />
    </footer>
    <Modal v-if="modalIsOpen" :modalIsOpen="modalIsOpen" :closeModal="closeModal" :buttonOptions="true"
      :confirmarDelete="confirmarDelete" :DeleteId="deletar" :modalText="$t('commons.modalTextDelete')" />
    <Modal v-if="modalDeleted" :modalIsOpen="modalDeleted" :closeModal="closeModal"
      :modalText="$t('commons.modalTextDeleted')" />

  </div>
</template>
  
<script>
import { BTable } from 'bootstrap-vue'
import HeaderRegister from "@/@core/components/register/HeaderRegister.vue";
import SearchBarRegister from "@/@core/components/register/SearchBarRegister.vue";
import Pagination from "@/@core/components/commons/pagination/Pagination.vue";
import Modal from "@/@core/components/commons/modal/Modal.vue";

import EyeIcon from "@/assets/img/eye.svg";
import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";

export default {
  name: "ViewSiproquim",
  components: {
    HeaderRegister,
    SearchBarRegister,
    Pagination,
    Modal,
    EyeIcon,
    EditIcon,
    DeleteIcon,
    BTable
  },
  data() {
    return {
      modalIsOpen: false,
      modalDeleted: false,
      deletar: null,
      totalItems: 2,
      itemsPerPage: 2,
      Indice: 0,
      filter: null,
      fields: [
        "CódigoSiproquim",
        { key: "Descrição", sortable: true },
        { key: "ações" },
      ],
      items: [{ CódigoSiproquim: "TPN12461030", Descrição: "PERMANGANATO DE POTÁSSIO" },
      { CódigoSiproquim: "TPN12491033", Descrição: "PIPERONAL" }],
    };
  },

  mounted() {
    this.$store.dispatch("siproquimState/totalSiproquim");
  },

  watch: {
    search() {
      this.$store.dispatch("siproquimState/totalSiproquim");
    },
  },

  methods: {
    RedirectUrl(url, item) {
      this.$store.state.codSip = item.CódigoSiproquim
      this.$store.state.Descri = item.Descrição
      this.$router.push(url)
    },
    deletarFornecedor(id) {
      this.modalIsOpen = true;
      this.deletar = id;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalDeleted = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
  
<style scoped>
main {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 10px 50px 0 150px;
  left: 301px;
  width: 81%;
  margin-left: 0%;
}

.acoes {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
</style>
  